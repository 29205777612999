.singleClaim_createExpense {
  text-align: right;
}

.singleClaim_claimtype .MuiSelect-select {
  padding: 8.5px 14px !important;
}

.singleClaim_createExpense button {
  width: 15rem;
  margin: 0;
}
.ladder_heading {
  color: #2e3192;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid lightblue;
  background: #0dcaf026;
  height: 3rem;
}
.ladder_heading_data {
  font-size: 14px;
  height: 3rem;
  border-bottom: 1px solid lightblue;
}
.ladder_heading th {
  font-weight: 500;
}
.ladder_heading_data td {
  font-weight: 500;
}

.custom-header {
  background-color: rgb(221, 241, 238);
  color: #00a87e;
  border-bottom: 1px solid #00a87e;
  font-family: GilroyMedium;
  font-size: 14px;
  /* padding: 5px 10px; */
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
  max-height: 800px !important;
}

.table-wrapper-scroll-y {
  display: block;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 15px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #e9e4e4;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

@media (max-width: 768px) {
  .table-wrapper-scroll-y {
    overflow-y: auto;
    overflow-x: scroll;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 20px;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #e9e4e4;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 1428px) {
  .custom-header {
    padding: 5px 20px !important;
  }
  .reject-button {
    padding-left: 0 !important;
    margin-left: 0.5rem;
  }
  .reject-commomn-button {
    display: flex;
    margin-top: 0.9rem !important;
  }
}
.reject-button {
  padding-left: 10px;
}

@media only screen and (min-width: 991px) and (max-width: 1320px) {
  .tourPlanNameBtn {
    margin-right: 200px;
    margin-left: 5px;
  }
}
.expense-buttons {
  justify-content: flex-end;
}
@media only screen and (max-width: 351px) {
  .expense-buttons {
    justify-content: center !important;
  }
}

.view-all-claims .custom-header,
.custom-cell {
  padding: 10px;
  text-align: center;
}
.custom-checkbox {
  display: flex;
  justify-content: center;
  top: 3px;
  position: relative;
  cursor: pointer;
  font-size: 22px; /* Controls the size of the checkbox */
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  display: inline-block;
  width: 20px; /* Size of the checkbox */
  height: 20px; /* Size of the checkbox */
  background-color: white;
  border: 2px solid #00a87e;
  border-radius: 50%; /* Makes the checkbox circular */
  position: relative;
  transition: background-color 0.2s ease-in-out;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #00a87e; /* Green fill color when checked */
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 5.5px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
